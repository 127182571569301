import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';

import { TagManagerService } from '@flashy/tag-manager/data-access';

@Injectable({
  providedIn: 'root',
})
export class RedirectManagerService {
  redirectRegisteredUrl = '';
  private routesHistorySet = new Set<NavigationEnd>();

  constructor(
    private router: Router,
    private tagManagerService: TagManagerService
  ) {}

  // Redirect to history routes by index
  async redirectToIndex(index: number): Promise<void> {
    const event = [...this.routesHistorySet].reverse()[index];
    await this.router.navigateByUrl(event.url);
  }

  // Start collecting the events
  collectEvents(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event) => {
        // if (event.url.includes(';clearRedirect=true') && this.redirectFragments.length) {
        //   this.redirectFragments = []; // Clear redirect fragments
        // }
        this.addRouter(event);
      });
  }

  // Register
  registerRedirect(url: string): void {
    this.redirectRegisteredUrl = url;
  }

  async doRegisteredRedirect(defaultTo?: string | UrlTree): Promise<void> {
    try {
      if (this.redirectRegisteredUrl) {
        const navigate = await this.router.navigateByUrl(
          this.redirectRegisteredUrl
        );
        console.log(navigate);
        this.redirectRegisteredUrl = '';
      } else if (!this.redirectRegisteredUrl && defaultTo) {
        console.log(defaultTo);
        await this.router.navigateByUrl(defaultTo);
      }
    } catch (e) {}
  }

  private addRouter(event: NavigationEnd): void {
    this.tagManagerService.sendPageEvent();
    this.routesHistorySet.add(event);
    if (this.routesHistorySet.size > 3) {
      const events = [...this.routesHistorySet].slice(-3);
      this.routesHistorySet = new Set<NavigationEnd>(events);
    }
  }
}
